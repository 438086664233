<template>
  <div class="HCInformation">
    <descriptions :descriptionData="desData" />
    <template v-if="position === 'quto'">
      <el-descriptions
        style="margin-bottom: -5px"
        title="补充信息"
        v-if="position === 'quto'"
      />
      <el-form
        :model="formData"
        v-if="position === 'quto'"
        :rules="rules"
        :disabled="statusDisable"
        label-position="top"
        ref="ruleForm"
        style="margin-bottom: 20px"
      >
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item label="Title English" prop="titleEnglish">
              <el-input v-model="formData.titleEnglish"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Replacement/New HC" prop="region">
              <el-select
                v-model="formData.replacementNewHc"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in ReplacementNewHCList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Parent Function" prop="region">
              <el-select
                v-model="formData.parentFunction"
                @change="getChild"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in ParentFunctionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Child Function" prop="region">
              <el-select
                clearable
                filterable
                v-model="formData.childFunction"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in ChildFunctionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item
              label="Relationship Effective start Date"
              prop="titleEnglish"
            >
              <el-date-picker
                v-model="formData.relationshipEffectiveStartDate"
                placeholder="Relationship Effective start Date"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Relationship Type" prop="titleEnglish">
              <el-input v-model="formData.relationshipType"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-descriptions title="预算调整" v-if="position === 'quto'" />

      <BugetTable
        ref="BugetTableRef"
        v-if="position === 'quto'"
        :currentBuget="this.currentBuget"
        :BugetTableList="BugetTableList"
      />
    </template>
  </div>
</template>

<script>
import descriptions from '@/components/descriptions/descriptions'
import BugetTable from '@/views/quotaManagement/components/BugetTable'
import {
  desData,
  financialDesData
} from '@/views/quotaManagement/components/desData'
import {
  getChildFunction,
  getDesData,
  getParentFunction,
  getReplacementNewHCList,
  saveHcInformation
} from '@/api/quto'
import { mergeDesDataOfPort } from '@/utils/util'
import { financeGet } from '@/api/finance'
export default {
  name: 'HCInformation',
  components: {
    BugetTable,
    descriptions
  },
  props:{
    id: {
      type: String
    },
    params:{
      type:Object,
      default:{
        status:'',
        position:'',

      }
    }
  },
  computed: {
    statusDisable() {
      return this.status === 'chat'
    }
  },
  watch:{
    'params.id': {
      handler(val) {
        if (val) {
          console.log(val);
          let position = this.$route.query.position || this.params.position
          if (position) {
            this.position = position
          }
          // this.getData()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    submit() {
      if (this.checkBudgetTableRef()) {
        const data = {
          id: this.$route.query.id || this.params.id || this.id,
          ...this.formData,
          currentBuget: this.$refs.BugetTableRef.currentNumber,
          bugetList: this.$refs.BugetTableRef.tableData
        }
        saveHcInformation(data).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('保存成功')
          }
        })
      }
    },
    checkBudgetTableRef() {
      let tableData = this.$refs.BugetTableRef.tableData
      let isSave = tableData.some((item) => item.formStatus === 'edit')
      if (isSave) {
        this.$message.warning('预算调整未保存')
        return false
      } else {
        return true
      }
    },
    async getChild() {
      const data = {
        parentFunction: this.formData.parentFunction
      }
      let childFun = await getChildFunction(data)
      if (childFun && childFun.code && childFun.code === 200) {
        if (childFun.data) {
          this.formData.childFunction = ''
          this.ChildFunctionList = childFun.data
        }
      }
    },
    async getData(){
      const data = {
        id: this.$route.query.id || this.params.id || this.id
      }
      if (this.position === 'hc') {
        this.desData = financialDesData
        let DesData = await financeGet(data)
        if (DesData && DesData.code && DesData.code === 200) {
          if (DesData.data) {
            mergeDesDataOfPort(this.desData, DesData.data)
            Object.keys(this.formData).forEach((item) => {
              this.formData[item] = DesData.data[item]
            })
            this.BugetTableList = DesData.data.bugetList
            this.currentBuget = Number(DesData.data.annualTotalCost)
          }
        }
      } else {
        let DesData = await getDesData(data)
        if (DesData && DesData.code && DesData.code === 200) {
          if (DesData.data) {
            mergeDesDataOfPort(this.desData, DesData.data)
            Object.keys(this.formData).forEach((item) => {
              this.formData[item] = DesData.data[item]
            })
            this.BugetTableList = DesData.data.bugetList
            this.currentBuget = Number(DesData.data.annualTotalCost)
          }
        }
      }

      let newHc = await getReplacementNewHCList()
      if (newHc && newHc.code && newHc.code === 200) {
        if (newHc.data) {
          this.ReplacementNewHCList = newHc.data
        }
      }

      let parentData = await getParentFunction()
      if (parentData && parentData.code && parentData.code === 200) {
        if (parentData.data) {
          this.ParentFunctionList = parentData.data
        }
      }
      let childFun = await getChildFunction()
      if (childFun && childFun.code && childFun.code === 200) {
        if (childFun.data) {
          this.ChildFunctionList = childFun.data
        }
      }
    }

  },
  async created() {
    let status = this.$route.query.status || this.params.status
    if (status) {
      this.status = status
    }
    // if (this.$route.query.id) {
      let position = this.$route.query.position || this.params.position
      if (position) {
        this.position = position
      }
    await this.getData()
    // }
    // else {
    //   await this.$router.push({
    //     path: '/employmentApplication/quotaManagement'
    //   })
    // }
  },
  data() {
    return {
      desData,
      formData: {
        titleEnglish: '',
        replacementNewHc: '',
        parentFunction: '',
        childFunction: '',
        relationshipEffectiveStartDate: '',
        relationshipType: ''
      },
      position: 'quto',
      rules: {},
      BugetTableList: [],
      ReplacementNewHCList: [],
      ParentFunctionList: [],
      ChildFunctionList: [],
      currentBuget: '',
      status: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.HCInformationButton {
  float: right;
  margin-right: 10px;
}
</style>
