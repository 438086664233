<template>
  <div class="BugetTable">
    <PocTable
      v-loading="loading"
      :data="tableData"
      :current-page.sync="pageConfig.pageNum"
      :total="pageConfig.total"
      hidepage
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
    >
      <div slot="tip">
        <div>
          Current Budget:&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
            currentNumber
          }}
        </div>
        <div class="button" v-if="statusDisable">
          <el-button @click="submit">保存</el-button>
          <el-button @click="add">新增</el-button>
        </div>
      </div>

      <el-table-column
        key="id"
        prop="id"
        label="Index"
        :fixed="null"
        :sortable="true"
      />
      <el-table-column
        key="type"
        prop="type"
        label="Type"
        :fixed="null"
        :sortable="true"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.type }}</span>
        </template></el-table-column
      >
      <el-table-column
        key="account"
        prop="account"
        label="Account"
        :fixed="null"
        :sortable="true"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.formStatus === 'only'">{{
            scope.row.account
          }}</span>
          <span v-else>
            <el-input-number v-model="scope.row.account" />
          </span> </template
      ></el-table-column>
      <el-table-column
        key="hc"
        prop="hc"
        label="HC"
        :fixed="null"
        :sortable="true"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.formStatus === 'only'">{{
            scope.row.label
          }}</span>
          <span v-else>
            <el-select v-model="scope.row.hc" placeholder="请选择">
              <el-option
                v-for="item in hcList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                @click.native="selectHc(scope.row, item.label)"
              >
              </el-option>
            </el-select>
          </span> </template
      ></el-table-column>

      <el-table-column
        label="Operation"
        v-if="statusDisable"
        width="80"
        fixed="right"
        align="center"
      >
        <template slot-scope="scope">
          <i
            class="poc-icon-primary poc-size12 el-icon-delete"
            @click="onDelete(scope.row)"
          ></i>
        </template>
      </el-table-column>
    </PocTable>
  </div>
</template>

<script>
import { checkBuget, getHcList } from '@/api/quto'

export default {
  mixins: [$PCommon.TableMixin],
  name: 'BugetTable',
  props: {
    currentBuget: {
      default: 0
    },
    BugetTableList: {
      default: []
    }
  },
  watch: {
    BugetTableList: {
      handler(val) {
        if (val) {
          this.tableData = val
        }
      },
      deep: true
    },
    currentBuget: {
      handler(val) {
        if (val) {
          this.currentNumber = val
          this.calculate()
        }
      },
      deep: true
    }
  },
  data() {
    return {
      loading: false,
      currentNumber: '',
      tableData: [],
      hcList: [],
      status: ''
    }
  },
  computed: {
    statusDisable() {
      return this.status !== 'chat'
    }
  },
  created() {
    let status = this.$route.query.status
    if (status) {
      this.status = status
    }
    const data = {
      id: this.$route.query.id
    }
    getHcList(data).then((res) => {
      if (res && res.code === 200) {
        if (res.data) {
          this.hcList = res.data
        }
      }
    })
  },

  methods: {
    selectHc(item, val) {
      item.label = val
    },
    add() {
      if (this.checkCount()) {
        const data = {
          type: '借入',
          id: this.tableData.length + 1,
          account: 0,
          formStatus: 'edit',
          hc: ''
        }
        this.tableData.push(data)
      } else {
        this.$message.warning('预算不足')
      }
    },
    countHc(val) {
      let findData = this.hcList.find((item) => item.value === val)
      if (findData) {
        return findData.label
      }
    },
    checkCount() {
      let sum = 0
      if (this.tableData.length) {
        this.tableData.forEach((item) => {
          sum = Number(item.account) + sum
        })
      }
      return this.currentBuget > sum
    },
    calculate() {
      let curentNum = this.currentBuget
      this.tableData.forEach((item) => {
        if (item.type === '借入') {
          curentNum = Number(curentNum) + Number(item.account)
        } else {
          curentNum = Number(curentNum) - Number(item.account)
        }
      })
      this.currentNumber = curentNum
    },
    checkIsEmpty() {
      let account = this.tableData.some((item) => item.account === 0)
      if (account) {
        this.$message.warning('Account is required')
        return false
      }
      let hc = this.tableData.some((item) => item.hc.length === 0)
      if (hc) {
        this.$message.warning('HC is required')
        return false
      }
      return true
    },
    submit() {
      if (this.checkCount()) {
        if (this.checkIsEmpty()) {
          this.tableData.forEach((item) => {
            // item.label = this.countHc(item.hc)
            item.formStatus = 'only'
          })
          this.calculate()
        }
      } else {
        this.$message.warning('预算不足')
      }
    },
    onDelete(val) {
      let index = this.tableData.findIndex((item) => item.id === val.id)
      this.tableData.splice(index, 1)
      this.calculate()
    }
  }
}
</script>
<style lang="scss" scoped>
.BugetTable {
  .poc-table {
    .poc-table-tip {
      div:first-child {
        width: 100%;
        display: flex;
        .button {
          display: flex;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
