export const desData = [
  {
    title: '基本信息',
    column: 4,
    content: [
      {
        label: 'Number',
        key: 'number',
        value: ''
      },
      {
        label: 'Country',
        key: 'countryName',
        value: ''
      },
      {
        label: 'Labor Code',
        key: 'laborCodeName',
        value: ''
      },
      {
        label: 'Assigment/project (Level2)',
        key: 'assigmentProjectLevel2',
        value: ''
      },
      {
        label: 'Description (Level3)',
        key: 'descriptionLevel3',
        value: ''
      },

      {
        label: 'Client',
        key: 'client',
        value: ''
      },
      {
        label: 'HFM Code',
        key: 'hfmCode',
        value: ''
      },
      {
        label: 'Business Unit Name',
        key: 'businessUnitName',
        value: ''
      },
      {
        label: 'Department',
        key: 'departmentName',
        value: ''
      },
      {
        label: 'Work Location',
        key: 'workingLocationName',
        value: ''
      },

      {
        label: 'Job Title',
        key: 'jobTitle',
        value: ''
      },
      {
        label: 'Estimated Start Date',
        key: 'estimatedStartDate',
        value: ''
      },

      {
        label: 'Internal candidate or new hire',
        key: 'internalCandidateOrNewHireName',
        value: ''
      },
      {
        label: 'Legal Entity',
        key: 'legalEntity',
        value: ''
      },
      {
        label: 'CEO',
        key: 'ceoName',
        value: ''
      },
      {
        label: 'CEO-1',
        key: 'ceoName1',
        value: ''
      },
      {
        label: 'New Candidate Name',
        key: 'newCandidateName',
        value: ''
      },
      {
        label: 'Lion ID of LM',
        key: 'lineManagerId',
        value: ''
      },
      {
        label: 'Name of LM',
        key: 'lineManagerName',
        value: ''
      },
      // {
      //   label: 'LM Email',
      //   key: 'lineManagerEmail',
      //   value: ''
      // },
      {
        label: 'Open less than 90 Days or Freelance Utillized',
        key: 'openLessThan90daysOrFreelanceUtillized',
        value: ''
      },
      {
        label: 'In Final Recruitment Stage',
        key: 'inFinalRecruitmentStage',
        value: ''
      }
    ]
  },
  {
    title: '工作制&薪水',
    column: 4,
    content: [
      {
        label: 'Work Schedules',
        key: 'workSchedulesName',
        value: ''
      },
      {
        label: 'Base Salary (Local Currency)',
        key: 'baseSalary',
        value: ''
      },
      {
        label: '是否十三薪',
        key: 'thirteenSalary',
        value: ''
      },
      {
        label: 'Benefits (Local Currency)',
        key: 'benefits',
        value: ''
      },
      {
        label: 'Relocation Cost',
        key: 'relocationCost',
        value: ''
      },
      {
        label: 'Annual Total Cost (Local Currency)',
        key: 'annualTotalCost',
        value: ''
      },
      {
        label: 'Euro Conv',
        key: 'euroConv',
        value: ''
      },
      {
        label: 'Annual Compensation(euros)',
        key: 'annualCompensation',
        value: ''
      },
      {
        label: '100%Billable',
        key: 'bill100Percent',
        value: ''
      },
      {
        label: 'Budget within latest RF (Y/N)',
        key: 'budgetWithinLastRf',
        value: ''
      },
      {
        label: 'Hire Does Not Increase Capacity',
        key: 'hireDoesNotIncreaseCapacity',
        value: ''
      },
      {
        label: 'Internal candidate or new hire',
        key: 'internalCandidateOrNewHireName',
        value: ''
      }
    ]
  },
  {
    title: 'Fill Back',
    column: 4,
    content: [
      {
        label: 'Type1',
        key: 'type1Id',
        value: ''
      },
      {
        label: 'If Backfill,name of departing employee',
        key: 'ifBackfillNameOfDepartingEmployee',
        value: ''
      },
      {
        label: 'If Backfill,date of departure',
        key: 'ifBackfillDateOfDeparture',
        value: ''
      },
      {
        label: 'If Backfill,where did employee go',
        key: 'ifBackfillWhereDidEmployeeGo',
        value: ''
      },
      {
        label:
          'If Backfill,Departing Employee Annual Total Cost (Local Currency)',
        key: 'ifBackfillDepartingEmployeeAnnualTotalCost',
        value: ''
      },
      {
        label:
          'If Backfill,departing Employee Annual Base Salary (Local Currency)',
        key: 'ifBackfillDepartingEmplyeeAnnualBaseSalary',
        value: '',
        span: 2
      }
    ]
  },
  {
    title: '其它',
    column: 4,
    content: [
      {
        label: 'Event Reason Name',
        key: 'eventReasonName',
        value: ''
      },
      {
        label: 'Cost Center',
        key: 'costCenter',
        value: ''
      },
      {
        label: 'Job Classification',
        key: 'jobClassification',
        value: ''
      },
      {
        label: 'Title English',
        key: 'titleEnglish',
        value: ''
      },
      {
        label: 'Parent Function',
        key: 'parentFunction',
        value: ''
      },
      {
        label: 'Child Function',
        key: 'childFunction',
        value: ''
      },
      {
        label: 'Relationship Effective start date',
        key: 'relationshipEffectiveStartDate',
        value: ''
      },
      {
        label: 'Relationship Type',
        key: 'relationshipType',
        value: ''
      },
      {
        label: 'Business Case to Justify the Recruitment',
        key: 'businessCaseToJustifyTheRecruitment',
        value: ''
      }
    ]
  }
]
export const financialDesData = [
  {
    title: '基本信息',
    column: 4,
    content: [
      {
        label: 'Number',
        key: 'number',
        value: ''
      },
      {
        label: 'Country',
        key: 'countryName',
        value: ''
      },
      {
        label: 'Labor Code',
        key: 'laborCodeName',
        value: ''
      },
      {
        label: 'Assigment/project (Level2)',
        key: 'assigmentProjectLevel2',
        value: ''
      },
      {
        label: 'Description (Level3)',
        key: 'descriptionLevel3',
        value: ''
      },

      {
        label: 'Client',
        key: 'client',
        value: ''
      },
      {
        label: 'HFM Code',
        key: 'hfmCode',
        value: ''
      },
      {
        label: 'Business Unit Name',
        key: 'businessUnitName',
        value: ''
      },
      {
        label: 'Department',
        key: 'departmentName',
        value: ''
      },
      {
        label: 'Work Location',
        key: 'workingLocationName',
        value: ''
      },

      {
        label: 'Job Title',
        key: 'jobTitle',
        value: ''
      },
      {
        label: 'Estimated Start Date',
        key: 'estimatedStartDate',
        value: ''
      },

      {
        label: 'Internal candidate or new hire',
        key: 'internalCandidateOrNewHireName',
        value: ''
      },
      {
        label: 'Legal Entity',
        key: 'legalEntity',
        value: ''
      },
      {
        label: 'CEO',
        key: 'ceoName',
        value: ''
      },
      {
        label: 'CEO-1',
        key: 'ceoName1',
        value: ''
      },
      {
        label: 'New Candidate Name',
        key: 'newCandidateName',
        value: ''
      },
      {
        label: 'Lion ID of LM',
        key: 'lineManagerId',
        value: ''
      },
      {
        label: 'Name of LM',
        key: 'lineManagerName',
        value: ''
      },
      // {
      //   label: 'LM Email',
      //   key: 'lineManagerEmail',
      //   value: ''
      // },
      {
        label: 'Open less than 90 Days or Freelance Utillized',
        key: 'openLessThan90daysOrFreelanceUtillized',
        value: ''
      },
      {
        label: 'In Final Recruitment Stage',
        key: 'inFinalRecruitmentStage',
        value: ''
      }
    ]
  },
  {
    title: '工作制&薪水',
    column: 4,
    content: [
      {
        label: 'Work Schedules',
        key: 'workSchedulesName',
        value: ''
      },
      {
        label: 'Base Salary (Local Currency)',
        key: 'baseSalary',
        value: ''
      },
      {
        label: '是否十三薪',
        key: 'thirteenSalary',
        value: ''
      },
      {
        label: 'Benefits (Local Currency)',
        key: 'benefits',
        value: ''
      },
      {
        label: 'Relocation Cost',
        key: 'relocationCost',
        value: ''
      },
      {
        label: 'Annual Total Cost (Local Currency)',
        key: 'annualTotalCost',
        value: ''
      },
      {
        label: 'Euro Conv',
        key: 'euroConv',
        value: ''
      },
      {
        label: 'Annual Compensation(euros)',
        key: 'annualCompensation',
        value: ''
      },
      {
        label: '100%Billable',
        key: 'bill100Percent',
        value: ''
      },
      {
        label: 'Budget within latest RF (Y/N)',
        key: 'budgetWithinLastRf',
        value: ''
      },
      {
        label: 'Hire Does Not Increase Capacity',
        key: 'hireDoesNotIncreaseCapacity',
        value: ''
      }
      // {
      //   label: 'Internal candidate or new hire',
      //   key: 'internalCandidateOrNewHireName',
      //   value: ''
      // }
    ]
  },
  {
    title: 'Fill Back',
    column: 4,
    content: [
      {
        label: 'Type1',
        key: 'type1Id',
        value: ''
      },
      {
        label: 'If Backfill,name of departing employee',
        key: 'ifBackfillNameOfDepartingEmployee',
        value: ''
      },
      {
        label: 'If Backfill,date of departure',
        key: 'ifBackfillDateOfDeparture',
        value: ''
      },
      {
        label:
          'If Backfill,Departing Employee Annual Total Cost (Local Currency)',
        key: 'ifBackfillDepartingEmployeeAnnualTotalCost',
        value: ''
      },
      {
        label:
          'If Backfill,departing Employee Annual Base Salary (Local Currency)',
        key: 'ifBackfillDepartingEmplyeeAnnualBaseSalary',
        value: ''
      }
    ]
  },
  {
    title: '其它',
    column: 4,
    content: [
      {
        label: 'Event Reason Name',
        key: 'eventReasonName',
        value: ''
      },
      {
        label: 'Cost Center',
        key: 'costCenter',
        value: ''
      },
      {
        label: 'Job Classification',
        key: 'jobClassification',
        value: ''
      },
      {
        label: 'Business Case to Justify the Recruitment',
        key: 'businessCaseToJustifyTheRecruitment',
        value: ''
      },
      {
        label: 'Reject Reason',
        key: 'rejectReason',
        value: ''
      }
    ]
  }
]
