import request from '@/utils/request'
import { exportDownload } from "@/api/export";

export const financeList = (data) => {
  return request({
    url: '/finance/list',
    method: 'POST',
    data
  })
}
export const getLaborCode = () => {
  return request({
    url: '/finance/getLaborCode'
  })
}
export const approve = (data) => {
  return request({
    url: '/finance/approve',
    method: 'POST',
    data
  })
}
export const reject = (data) => {
  return request({
    url: '/finance/reject',
    method: 'POST',
    data
  })
}
export const financeGet = (data) => {
  return request({
    url: '/finance/get',
    params: {
      ...data
    }
  })
}
export const getFinanceRecord = (data) => {
  return request({
    url: '/finance/getFinanceRecord',
    params: {
      ...data
    }
  })
}
export const download = () => {
  exportDownload('finance/hc/download/list', {})
}

export const isReject = (data) => {
  return request({
    url: '/finance/cfoRejectCheck',
    method: 'POST',
    data
  })
}
export const getMessageList = (data) => {
  return request({
    url: '/finance/getMessageList',
    params: {
      ...data
    }
  })
}
export const saveMessage = (data) => {
  return request({
    url: '/finance/saveMessage',
    method: 'POST',
    data
  })
}
